import React from "react";
import { useCookieConsents } from "@enzsft/react-cookie-consents";
import IconCookie from "../../icons/icon-food-cookie.svg";

export const CookieBanner = () => {
  const cookieConsents = useCookieConsents();

  if (typeof window === "undefined") {
    return null;
  }

  if (cookieConsents.get().length > 0) {
    return null;
  }

  return (
    <>
      <div className="fixed w-full bottom-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
          <div className="rounded-lg shadow-xs overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <IconCookie className="fill-current text-gray-900" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm leading-5 font-medium text-gray-900">
                    About cookies on this site
                  </p>
                  <p className="mt-1 text-sm leading-5 text-gray-500">
                    We use cookies to collect and analyse information on site
                    performance and usage and to enhance and customise content.
                  </p>
                  <div className="mt-2">
                    <button
                      onClick={() => {
                        cookieConsents.add("all");
                        tagrush.consent(true);
                      }}
                      className="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >
                      Accept all cookies
                    </button>
                    <button
                      onClick={() => cookieConsents.add("none")}
                      className="ml-6 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >
                      Do not accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
