import React from "react";
import PropTypes from "prop-types";

import GlobalStyles from "../styles/GlobalStyles";
import { CookieBanner } from "./sections/CookieBanner";
import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    {children}
    <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
      <CookieBanner />
    </CookieConsentsProvider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
